import React from "react";
import { MenuBar } from "../components";
import { Link as RouterLink } from "react-router-dom";
import {
	Alert,
	AlertTitle,
	Box,
	Container,
	Grid,
	Card,
	CardMedia,
	CardContent,
	Typography,
	Chip,
	TextField,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
	OutlinedInput,
	InputLabel,
	Select,
	MenuItem,
	ListItemText,
	Checkbox,
	InputAdornment,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import RouteIcon from "@mui/icons-material/Route";
import TimerIcon from "@mui/icons-material/Timer";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import LayersIcon from "@mui/icons-material/Layers";
import SaveIcon from "@mui/icons-material/Save";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import { enqueueSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const maxLengthName = 50;
const maxLengthDesc = 300;
const maxFileSizeMB = 10;
const challengeIcons = [<RouteIcon />, <TimerIcon />, <FilterHdrIcon />];
const fileTypes = ["jpg", "jpeg", "png", "gif"];

const initialState = {
	challengeName: "",
	description: "",
	isTeam: false,
	isDeloitteAttribute: false,
	attribute: "O", // O - office | T - title
	isSingle: false,
	startDate: Moment(new Date()).format("YYYY-MM-DD"),
	endDate: Moment(new Date()).add(1, "d").format("YYYY-MM-DD"),
	disciplines: [],
	totalGoal: 0,
	goalType: 1,
	fileUploadChildren: (
		<p>
			<strong>
				<u>Upload</u>
			</strong>{" "}
			or drop a challenge image <br />
			(max. 10MB)
		</p>
	),
	file: "",
	extension: "",
	descriptionLength: 0,
	challengeNameLength: 0,
	successDialog: false,
	lastCreatedChallengeId: null,
	fileUploadKey: 0,
	submitLoading: false,
	selected: -1,
};

const equalsCheck = (a, b) =>
	a.length === b.length && a.every((v, i) => v === b[i]);

class AdminPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChangeSelect = this.handleChangeSelect.bind(this);
		this.handleChangeFile = this.handleChangeFile.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleShowNotification = this.handleShowNotification.bind(this);
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleChangeChallenge = this.handleChangeChallenge.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.retrieveChallenges = this.retrieveChallenges.bind(this);
	}

	componentDidMount() {
		let urls = [
			"api/adPage/loadData",
			process.env.REACT_APP_ORACLE_PROD + "/ords/admin/DB/getCategoryStats",
			process.env.REACT_APP_ORACLE_PROD +
				"/ords/admin/DB/getAverageTeamMembers",
		];
		Promise.all(
			urls.map((url) =>
				fetch(url, { accept: "application/json" }).then((resp) => resp.json())
			)
		).then((data) => {
			data[0].disciplines.forEach((discipline) => {
				data[0].dimensions.forEach((dimension) => {
					if (
						data[1].findIndex(
							(cs) => cs.cat === discipline && cs.dim === dimension.id
						) < 0
					) {
						data[1].push({
							cat: discipline,
							dim: dimension.id,
							min: 0,
							avg: 0,
							med: 0,
							max: 0,
						});
					}
				});
			});
			this.setState({
				adminPayload: data[0],
				catStats: data[1],
				averageTeamMembers: data[2].avgTeamMembers,
			});
		});
	}

	retrieveChallenges() {
		fetch("api/adPage/loadData", {
			accept: "application/json",
		})
			.then((resp) => resp.json())
			.then((data) => this.setState({ adminPayload: data }));
	}

	handleShowNotification(notificationText, notificationVariant) {
		enqueueSnackbar(notificationText, {
			variant: notificationVariant,
		});
	}

	handleCloseDialog() {
		this.setState({ successDialog: false, isUpdate: false, isDelete: false });
	}

	handleChange(e) {
		let value = e.target.value;
		let name = e.target.name;
		/* eslint-disable no-fallthrough */
		switch (name) {
			case "challengeName":
			case "description":
				this.setState({ [name + "Length"]: value.length });
				break;
			case "isDeloitteAttribute":
				value = e.target.checked;
				break;
			case "isTeam":
				if (value === "false") {
					this.setState({ isDeloitteAttribute: false });
				}
			default:
				if (value === "true" || value === "false") {
					value = value === "true";
				}
		}
		/* eslint-enable no-fallthrough */
		this.setState({ [name]: value });
	}

	handleDateChange(target, value) {
		if (value !== null) {
			switch (target) {
				case "startDate":
					if (value > Moment(this.state.endDate)) {
						this.setState({
							endDate: value.add(1, "d").format("YYYY-MM-DD"),
							startDate: value.format("YYYY-MM-DD"),
						});
					} else {
						this.setState({ startDate: value.format("YYYY-MM-DD") });
					}
					break;
				default:
					if (value < Moment(this.state.startDate)) {
						this.setState({
							startDate: value.subtract(1, "d").format("YYYY-MM-DD"),
							endDate: value.format("YYYY-MM-DD"),
						});
					} else {
						this.setState({ endDate: value.format("YYYY-MM-DD") });
					}
					break;
			}
		}
	}

	handleBlur(e) {
		let value = e.target.value;
		let name = e.target.name;
		if (name === "totalGoal") {
			this.setState({ totalGoal: Number(value) });
		}
	}

	handleChangeSelect(e) {
		const {
			target: { value },
		} = e;
		this.setState({
			disciplines: typeof value === "string" ? value.split(",") : value,
		});
	}

	handleChangeFile(file) {
		if (file && file.size > maxFileSizeMB * 1024 * 1024) {
			this.setState({
				fileUploadChildren: (
					<p>
						<strong>
							<u>File too large!</u>
						</strong>{" "}
						Try uploading or dropping another image (max. 10MB)
					</p>
				),
				file: "",
			});
			this.handleShowNotification("Maximum file size exceeded", "error");
		} else {
			file &&
				this.setState({
					file: URL.createObjectURL(file),
					extension: /(?:\.([^.]+))?$/.exec(file.name)[1],
					fileUploadChildren: (
						<p>
							<strong>Uploaded successfully!</strong> Click here or drag another
							image to change it.
						</p>
					),
				});
		}
	}

	handleChangeChallenge(e) {
		let id = e.target.value;
		if (id < 0) {
			this.setState({
				challengeName: "",
				description: "",
				isTeam: false,
				isSingle: false,
				startDate: Moment(new Date()).format("YYYY-MM-DD"),
				endDate: Moment(new Date()).add(1, "d").format("YYYY-MM-DD"),
				disciplines: [],
				totalGoal: 0,
				goalType: 1,
				file: "",
				selected: id,
			});
		} else {
			let { adminPayload } = this.state;
			let challenge = adminPayload.challenges.find((ch) => ch.id === id);
			this.setState({
				challengeName: challenge.name,
				description: challenge.description,
				isTeam: challenge.isTeam,
				isSingle: challenge.isSingle,
				startDate: challenge.startDate,
				endDate: challenge.endDate,
				disciplines: challenge.categories,
				totalGoal: challenge.amount,
				goalType: challenge.dimensionId,
				file: "/img/" + challenge.image,
				selected: id,
				isDeloitteAttribute: challenge.isDeloitteAttribute,
				attribute: challenge.attribute,
			});
		}
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({ submitLoading: true });
		let {
			challengeName,
			description,
			isTeam,
			isDeloitteAttribute,
			attribute,
			isSingle,
			startDate,
			endDate,
			fileUploadKey,
			disciplines,
			totalGoal,
			goalType,
			file,
			extension,
			adminPayload,
			selected,
		} = this.state;
		let isUpdate = selected > -1;
		let newImage =
			isUpdate &&
			adminPayload.challenges.find((ch) => ch.id === selected).image !==
				file.substring(file.lastIndexOf("/") + 1);
		let formData = new FormData();
		formData.append(
			"jsonString",
			JSON.stringify({
				id: selected,
				name: challengeName,
				description: description,
				amount: totalGoal,
				dimensionId: goalType,
				startDate: startDate,
				endDate: endDate,
				isSingle: isSingle,
				isTeam: isTeam,
				categories: disciplines,
				isDeloitteAttribute: isDeloitteAttribute,
				attribute: attribute,
				newImage: newImage,
				imagename:
					!isUpdate || newImage
						? challengeName.replaceAll(" ", "_") + "." + extension
						: file.substring(file.lastIndexOf("/") + 1),
			})
		);
		fetch(file)
			.then((r) => r.blob())
			.then((blob) => {
				formData.append("file", blob);
				const requestOptions = {
					method: "POST",
					body: formData,
				};
				fetch("/api/adPage/createChallenge", requestOptions)
					.then((resp) => resp.json())
					.then((response) => {
						this.setState({ submitLoading: false });
						switch (response) {
							case 0:
								this.handleShowNotification(
									"Error parsing challenge data",
									"error"
								);
								break;
							case -1:
								this.handleShowNotification(
									"Database error creating or modifying challenge",
									"error"
								);
								break;
							default:
								this.setState(
									{
										...initialState,
										successDialog: true,
										lastCreatedChallengeId: response,
										lastChallengeName: challengeName,
										fileUploadKey: fileUploadKey + 1,
										isUpdate: isUpdate,
									},
									this.retrieveChallenges()
								);
						}
					});
			});
	}

	handleDelete(id) {
		fetch("/api/adPage/removeChallenge?id=" + id, { method: "DELETE" }).then(
			(resp) => {
				if (resp.ok) {
					let { challengeName, adminPayload } = this.state;
					adminPayload.challenges = adminPayload.challenges.filter(
						(ch) => ch.id !== id
					);
					this.setState({
						...initialState,
						successDialog: true,
						lastCreatedChallengeId: id,
						lastChallengeName: challengeName,
						isDelete: true,
						adminPayload: adminPayload,
					});
				} else {
					this.handleShowNotification(
						"Error deleting challenge with ID " + id,
						"error"
					);
				}
			}
		);
	}

	render() {
		let {
			adminPayload,
			catStats,
			challengeName,
			description,
			isTeam,
			isDeloitteAttribute,
			attribute,
			isSingle,
			startDate,
			endDate,
			successDialog,
			lastCreatedChallengeId,
			disciplines,
			totalGoal,
			goalType,
			file,
			descriptionLength,
			challengeNameLength,
			fileUploadKey,
			fileUploadChildren,
			lastChallengeName,
			submitLoading,
			selected,
			isUpdate,
			isDelete,
			averageTeamMembers,
		} = this.state;
		if (typeof adminPayload === "undefined") return null;
		let challengeIcon =
			challengeIcons[adminPayload.dimensions[goalType - 1].id - 1];
		let challengeUnit = adminPayload.dimensions[goalType - 1].unitShort;
		let singleIcon = isSingle ? (
			<LayersClearIcon sx={{ fontSize: 16, verticalAlign: "middle" }} />
		) : (
			<LayersIcon sx={{ fontSize: 16, verticalAlign: "middle" }} />
		);
		let originalChallenge =
			selected > -1
				? adminPayload.challenges.find((ch) => ch.id === selected)
				: null;
		if (originalChallenge && !originalChallenge.isDeloitteAttribute) {
			originalChallenge.attribute = attribute;
		}
		let targetDisciplines = disciplines.length === 0 ? ["All"] : disciplines;
		return (
			<>
				<MenuBar />
				<Container sx={{ my: 2 }} maxWidth="lg">
					<Grid container spacing={2}>
						<Grid item md={4} xs={12}>
							<Card className="animate-content-card-up">
								<CardContent>
									<Select
										name="challenges"
										fullWidth
										value={selected}
										onChange={this.handleChangeChallenge}
										input={<OutlinedInput />}
										size="small"
									>
										<MenuItem key={-1} value={-1}>
											<em>New Challenge</em>
										</MenuItem>
										{adminPayload.challenges.map((challenge) => (
											<MenuItem key={challenge.id} value={challenge.id}>
												{challenge.name}
											</MenuItem>
										))}
									</Select>
									<form onSubmit={this.handleSubmit}>
										<FormControl fullWidth size="small">
											<FileUploader
												key={fileUploadKey}
												classes="file-uploader"
												children={fileUploadChildren}
												handleChange={this.handleChangeFile}
												name="file"
												types={fileTypes}
											/>
										</FormControl>
										<TextField
											sx={{ mb: 1 }}
											name="challengeName"
											fullWidth
											label="Challenge Name"
											variant="outlined"
											value={challengeName}
											onChange={this.handleChange}
											helperText={
												<span
													style={{
														width: "100%",
														display: "flex",
														lineHeight: 1,
													}}
												>
													{maxLengthName -
														challengeNameLength +
														" / " +
														maxLengthName +
														" chars. remaining"}
												</span>
											}
											inputProps={{ maxLength: maxLengthName }}
											size="small"
											required
										/>
										<TextField
											sx={{ my: 1 }}
											name="description"
											fullWidth
											multiline
											rows={4}
											label="Challenge Description"
											variant="outlined"
											value={description}
											onChange={this.handleChange}
											helperText={
												<span
													style={{
														width: "100%",
														display: "flex",
														lineHeight: 1,
													}}
												>
													{maxLengthDesc -
														descriptionLength +
														" / " +
														maxLengthDesc +
														" chars. remaining"}
												</span>
											}
											inputProps={{ maxLength: maxLengthDesc }}
											size="small"
											required
										/>
										<FormControl fullWidth sx={{ my: 1 }} size="small">
											<Select
												name="disciplines"
												id="demo-multiple-checkbox"
												multiple
												displayEmpty
												value={disciplines}
												onChange={this.handleChangeSelect}
												input={<OutlinedInput />}
												renderValue={(selected) => {
													if (selected.length === 0) {
														return <em>All Disciplines</em>;
													} else {
														return selected.join(", ");
													}
												}}
												size="small"
											>
												<MenuItem key={0} value="" disabled>
													<em>All Disciplines</em>
												</MenuItem>
												{adminPayload.disciplines.map((name) => (
													<MenuItem key={name} value={name}>
														<Checkbox
															checked={disciplines.indexOf(name) > -1}
														/>
														<ListItemText primary={name} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<Grid container spacing={1}>
											<Grid item md={6} xs={12}>
												<FormControl fullWidth sx={{ my: 1 }} size="small">
													<InputLabel id="label-goal-total">
														Goal Total
													</InputLabel>
													<OutlinedInput
														fullWidth
														required
														name="totalGoal"
														type="number"
														label="Goal Total"
														value={totalGoal.toString()}
														onChange={this.handleChange}
														onBlur={this.handleBlur}
														endAdornment={
															<InputAdornment position="end">
																{challengeUnit}
															</InputAdornment>
														}
														size="small"
													/>
												</FormControl>
											</Grid>
											<Grid item md={6} xs={12}>
												<FormControl fullWidth sx={{ my: 1 }} size="small">
													<InputLabel id="label-goal">Goal Type</InputLabel>
													<Select
														name="goalType"
														value={goalType}
														label="Goal Type"
														onChange={this.handleChange}
														size="small"
														required
													>
														{adminPayload.dimensions.map((dimension) => (
															<MenuItem key={dimension.id} value={dimension.id}>
																{dimension.name} ({dimension.unitShort})
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item md={6} xs={12}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DatePicker
														name="startDate"
														label="Start Date"
														variant="outlined"
														value={dayjs(startDate)}
														onChange={(newValue) =>
															this.handleDateChange("startDate", newValue)
														}
														slotProps={{
															textField: {
																fullWidth: true,
																required: true,
																InputLabelProps: { shrink: true },
																size: "small",
																sx: { my: 1 },
															},
														}}
													/>
												</LocalizationProvider>
											</Grid>
											<Grid item md={6} xs={12}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DatePicker
														name="endDate"
														label="End Date"
														variant="outlined"
														value={dayjs(endDate)}
														onChange={(newValue) =>
															this.handleDateChange("endDate", newValue)
														}
														slotProps={{
															textField: {
																fullWidth: true,
																required: true,
																InputLabelProps: { shrink: true },
																size: "small",
																sx: { my: 1 },
															},
														}}
													/>
												</LocalizationProvider>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item>
												<FormControl size="small">
													<FormLabel>Team</FormLabel>
													<RadioGroup
														row
														value={isTeam}
														name="isTeam"
														onChange={this.handleChange}
													>
														<FormControlLabel
															value="true"
															control={<Radio size="small" />}
															label="Team"
														/>
														<FormControlLabel
															value="false"
															control={<Radio size="small" />}
															label="Solo"
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid item>
												<FormControlLabel
													label="Profile attrib."
													control={
														<Checkbox
															checked={isDeloitteAttribute}
															name="isDeloitteAttribute"
															onChange={this.handleChange}
															size="small"
														/>
													}
													disabled={!isTeam}
												/>
												<FormControl
													size="small"
													disabled={!isDeloitteAttribute}
												>
													<RadioGroup
														row
														value={attribute}
														name="attribute"
														onChange={this.handleChange}
													>
														<FormControlLabel
															value="O"
															control={<Radio size="small" />}
															label="Office"
														/>
														<FormControlLabel
															value="T"
															control={<Radio size="small" />}
															label="Title"
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid item>
												<FormControl size="small">
													<FormLabel>Activities</FormLabel>
													<RadioGroup
														row
														value={isSingle}
														name="isSingle"
														onChange={this.handleChange}
													>
														<FormControlLabel
															value="true"
															control={<Radio size="small" />}
															label="Single"
														/>
														<FormControlLabel
															value="false"
															control={<Radio size="small" />}
															label="Multiple"
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<LoadingButton
											fullWidth
											loading={submitLoading}
											loadingPosition="start"
											startIcon={<SaveIcon />}
											color="primary"
											variant="contained"
											type="submit"
											value="Submit"
											disabled={
												selected < 0
													? !(
															challengeName &&
															description &&
															startDate &&
															endDate &&
															totalGoal > 0 &&
															file
													  )
													: challengeName === originalChallenge.name &&
													  description === originalChallenge.description &&
													  startDate === originalChallenge.startDate &&
													  endDate === originalChallenge.endDate &&
													  totalGoal === originalChallenge.amount &&
													  isTeam === originalChallenge.isTeam &&
													  isSingle === originalChallenge.isSingle &&
													  equalsCheck(
															disciplines,
															originalChallenge.categories
													  ) &&
													  file === "/img/" + originalChallenge.image &&
													  isDeloitteAttribute ===
															originalChallenge.isDeloitteAttribute &&
													  attribute === originalChallenge.attribute
											}
										>
											Submit Challenge
										</LoadingButton>
										<Button
											fullWidth
											startIcon={<DeleteIcon />}
											color="error"
											disabled={selected < 0}
											variant="contained"
											onClick={() => this.handleDelete(selected)}
											sx={{ mt: 1 }}
										>
											Delete Challenge
										</Button>
									</form>
								</CardContent>
							</Card>
						</Grid>
						<Grid
							item
							md={8}
							xs={12}
							sx={{ display: "flex", alignItems: "top" }}
						>
							<div style={{ width: "100%" }}>
								<Card
									className="challenge-card animate-content-card-up"
									sx={{
										width: "100%",
										mb: 2,
									}}
								>
									<CardContent>
										<Box
											sx={{
												position: "relative",
												height: "100%",
												overflow: "hidden",
											}}
										>
											<Chip
												className="chip-participant"
												color="error"
												size="small"
												label="0 participants"
											/>
											<CardMedia
												component="img"
												height="100%"
												image={
													file
														? file
														: "https://fpoimg.com/762x104?text=No challenge image&bg_color=33333&text_color=fff"
												}
												alt="Challenge Type"
												sx={{ borderRadius: 1 }}
											/>
										</Box>
									</CardContent>
									<CardContent>
										<Grid
											container
											wrap="nowrap"
											justifyContent="space-between"
										>
											<Grid item zeroMinWidth>
												<Typography
													fontSize={"1.25rem"}
													fontWeight="bold"
													noWrap
												>
													{challengeName ? challengeName : "Challenge Name"}
												</Typography>
											</Grid>
											<Grid item>
												<Button
													fullWidth
													color="primary"
													variant="contained"
													size="small"
												>
													Join
												</Button>
											</Grid>
										</Grid>
										<Typography variant="overline">
											{disciplines.length > 0
												? disciplines.map((discipline, i) => [
														i > 0 && ", ",
														discipline,
												  ])
												: "All Disciplines"}
										</Typography>
									</CardContent>
									<CardContent sx={{ pt: 0 }}>
										<Grid container spacing={0.5}>
											<Grid item xs="auto">
												<Chip
													size="small"
													icon={isTeam ? <PeopleIcon /> : <PersonIcon />}
													label={isTeam ? "Team" : "Solo"}
												/>
											</Grid>
											<Grid item xs="auto">
												<Chip
													size="small"
													icon={challengeIcon}
													label={
														(Math.round(totalGoal * 100) / 100).toFixed(2) +
														" " +
														challengeUnit
													}
												/>
											</Grid>
											<Grid item xs="auto">
												<Chip
													icon={singleIcon}
													label={
														isSingle ? "Single Activity" : "Multiple Activities"
													}
													size="small"
												/>
											</Grid>
										</Grid>
										<Typography
											sx={{
												display: "flex",
												alignItems: "center",
												my: 1,
												fontSize: "14px",
											}}
										>
											<TodayIcon sx={{ fontSize: "18px!important", mr: 0.5 }} />
											{startDate !== endDate
												? (startDate &&
														Moment(startDate).format("MMM D, YYYY")) +
												  " - " +
												  (endDate && Moment(endDate).format("MMM D, YYYY"))
												: Moment(startDate).format("MMM D, YYYY")}
										</Typography>
										<Typography variant="body2" sx={{ mt: "8px" }}>
											{description ? description : "Description text..."}
										</Typography>
									</CardContent>
								</Card>
								{isTeam && (
									<Alert severity="info" sx={{ mb: 1 }}>
										<AlertTitle>
											Average team composition:{" "}
											{Math.round(averageTeamMembers * 100) / 100} member(s)
										</AlertTitle>
									</Alert>
								)}
								<Alert severity="info" sx={{ mb: 1 }}>
									<AlertTitle>
										Minimum, average, median and maximum{" "}
										{
											adminPayload.dimensions.find((dim) => dim.id === goalType)
												.unitLong
										}{" "}
										per athlete per week for selected disciplines
									</AlertTitle>
									<TableContainer sx={{ borderRadius: ".25rem" }}>
										<Table stickyHeader size="small">
											<TableHead>
												<TableRow>
													<TableCell align="left">Discipline</TableCell>
													<TableCell align="right">Min</TableCell>
													<TableCell align="right">Avg</TableCell>
													<TableCell align="right">Med</TableCell>
													<TableCell align="right">Max</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{targetDisciplines.map((discipline, i) => (
													<TableRow
														key={i}
														sx={{
															"&:last-child td, &:last-child th": {
																border: 0,
															},
														}}
													>
														<TableCell align="left">{discipline}</TableCell>
														<TableCell align="right">
															{catStats
																.filter(
																	(cs) =>
																		cs.cat === discipline && cs.dim === goalType
																)[0]
																.min.toFixed(2)}
														</TableCell>
														<TableCell align="right">
															{catStats
																.filter(
																	(cs) =>
																		cs.cat === discipline && cs.dim === goalType
																)[0]
																.avg.toFixed(2)}
														</TableCell>
														<TableCell align="right">
															{catStats
																.filter(
																	(cs) =>
																		cs.cat === discipline && cs.dim === goalType
																)[0]
																.med.toFixed(2)}
														</TableCell>
														<TableCell align="right">
															{catStats
																.filter(
																	(cs) =>
																		cs.cat === discipline && cs.dim === goalType
																)[0]
																.max.toFixed(2)}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Alert>
								<Alert severity="info">
									<AlertTitle>Challenge Creation Tips</AlertTitle>
									<ul style={{ paddingInlineStart: "14px" }}>
										<li>
											All images uploaded must be at least 104 pixels in height
											by 762 pixels in width, under 10 MB in file size, and one
											of these supported types: {fileTypes.join(", ")}.
										</li>
										<li>
											Do not include the word "challenge" in the challenge name
										</li>
										<li>
											Do not include the goal amount or type within the
											challenge name or description. Instead, use the Goal Total
											and Goal Type fields; if necessary, reference the
											rationale for the target (e.g., 6.2 miles is "10k")
										</li>
										<li>
											Single activity refers to challenges that must be
											completed within a single workout session.
										</li>
									</ul>
								</Alert>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Dialog
					open={successDialog}
					onClose={this.handleCloseDialog}
					aria-labelledby="success-dialog-title"
					fullWidth
				>
					<DialogTitle id="success-dialog-title">
						Challenge {isUpdate ? "Updated" : isDelete ? "Deleted" : "Created"}
					</DialogTitle>
					<DialogContent>
						<p>
							Challenge "{lastChallengeName}" with ID{" "}
							<strong>{lastCreatedChallengeId}</strong> successfully{" "}
							{isUpdate ? "updated!" : isDelete ? "deleted!" : "created!"}
						</p>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={this.handleCloseDialog}>
							Create Another Challenge
						</Button>
						<Button
							sx={{ ml: 1 }}
							variant="contained"
							component={RouterLink}
							to={"/"}
						>
							Visit Dashboard
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default AdminPage;
