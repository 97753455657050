import React, { useState, useEffect, useRef } from "react";
import {
	Card,
	CardContent,
	Paper,
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Select,
	MenuItem,
	Typography,
	Grid,
	Skeleton,
	Link,
	ListSubheader
} from "@mui/material";
import Moment from "moment";

const columns = [
	{ id: "rank", label: "R#" },
	{ id: "name", label: "Name" },
	{ id: "amount", label: "", align: "right" },
];

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export default function ChallengesLeaderboard(props) {
	const [challenge, setChallenge] = useState(props.default);
	const [page, setPage] = useState(0);
	const [totalParticipants, setTotalParticipants] = useState(0);
	const [challengeDetails, setChallengeDetails] = useState({});
	const [alreadyLoaded, setAlreadyLoaded] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [listItems, setListItems] = useState([]);
	const prevChallenge = usePrevious(challenge);
	useEffect(() => {
		if (challenge > -1) {
			let numParticipants = props.allChallenges.find(
				aChallenge => aChallenge.id === challenge
			).pt;
			if (challenge !== prevChallenge) {
				setTotalParticipants(numParticipants);
				setPage(1);
			};
			let index = alreadyLoaded.findIndex(
				loaded => loaded.id === challenge
			);
			if (index < 0) {
				let baseURL = "";
				switch (process.env.REACT_APP_TARGET) {
					case "qa":
						baseURL = process.env.REACT_APP_ORACLE_QA;
						break;
					case "dev":
						baseURL = process.env.REACT_APP_ORACLE_DEV;
						break;
					case "www":
						baseURL = process.env.REACT_APP_ORACLE_PROD;
						break;
					default:
						baseURL = process.env.REACT_APP_ORACLE_QA;
				};
				baseURL += ("/ords/admin/DB/getChallengeStats?id=" + challenge + "&pagenum=");
				let allPages = Array.from({length: Math.ceil(numParticipants/20)}, (_, i) => i + 1);
				Promise.all(
					allPages.map(pageNum =>
						fetch(baseURL + pageNum, { accept: "application/json" }).then((resp) => resp.json())
					)
				).then(data => {
					setChallengeDetails(data[0].challenge);
					let allAthletes = [];
					data.forEach(dbPage => {
						allAthletes = allAthletes.concat(dbPage.athletes);
					});
					setListItems(allAthletes);
					setAlreadyLoaded(oldAlreadyLoaded => [
						...oldAlreadyLoaded,
						{
							id: challenge,
							details: data[0].challenge,
							data: allAthletes,
						},
					]);
					setLoaded(true);
				});
			} else {
				setChallengeDetails(alreadyLoaded[index].details);
				setListItems(alreadyLoaded[index].data);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challenge]);

	const handleChangeChallenge = (event) => {
		setChallenge(event.target.value);
	};

	const handleChangePage = (__event, newPage) => {
		setPage(newPage + 1);
	};

	const renderColumnName = (column, name) => {
		switch (column) {
			case "amount":
				return typeof challengeDetails.dimensionId === "undefined"
					? name
					: props.dimensions.find(
							(dim) => dim.id === challengeDetails.dimensionId
					  ).name;
			case "name":
				return challenge < 0
					? name
					: props.allChallenges.find((ch) => ch.id === challenge).te
					? "Team"
					: "Athlete";
			default:
				return name;
		}
	};

	const formatAmount = (value, dimension, summary) => {
		let response = "";
		let unitShort = props.dimensions.find(
			(dim) => dim.name === dimension
		).unitShort;
		let digits = (dimension === "Trophies" || summary) ? 0 : 2;
		if (dimension === "Duration") {
			let mins = "0" + parseInt((value * 60) % 60);
			response =
				parseInt(value).toLocaleString("en-US") +
				" " +
				unitShort +
				" " +
				mins.substring(mins.length - 2) +
				" min";
		} else {
			response =
				value.toLocaleString("en-US", { minimumFractionDigits: digits, maximumFractionDigits: digits }) +
				" " +
				unitShort;
		}
		return response;
	};

	const buildList = (challenges) => {
		const today = new Date();
		const titles = ['Current', 'Future', 'Past'];
		const sections = [[], [], []]; // current, future, past
		challenges.filter(challenge => !challenge.mn).forEach(challenge => {
			const start = new Date(challenge.sd);
			const end = new Date(challenge.ed);
			const categoryIndex = start > today ? 1 : end < today ? 2 : 0;
			sections[categoryIndex].push(challenge);
		});
		return [
			buildItem(challenges.find(ch => ch.mn)),
		].concat(sections.map((challenges, i) => {
			const sortedChallenges = challenges.sort((a, b) => i === 2 ? new Date(b.sd) - new Date(a.sd) : new Date(a.sd) - new Date(b.sd));
			return (
				sortedChallenges.length === 0 ? [] :
				[<ListSubheader key={i}>{titles[i]}</ListSubheader>].concat(sortedChallenges.map(challenge => buildItem(challenge)))
			);
		}));
	};

	const buildItem = (challenge) => {
		return (
			<MenuItem key={challenge.id} value={challenge.id}>
				<div
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{challenge.na}
				</div>
			</MenuItem>
		);
	}

	return (
		loaded ? (
			<>
				<CardContent sx={{ pt: 0 }}>
					<Card>
						<CardContent>
							<Select
								value={challenge}
								onChange={handleChangeChallenge}
								variant="standard"
								size="small"
								sx={{ width: "100%" }}
								MenuProps={{ PaperProps: { style: { maxHeight: "40%" } } }}
							>
								{buildList(props.allChallenges)}
							</Select>
							<Grid sx={{ my: 0.5 }} container spacing={1}>
								<Grid item xs="auto">
									<Typography variant="overline" fontWeight={600}>
										{
											challengeDetails.goal
											+ " " +
											props.dimensions.find((dim) => dim.id === challengeDetails.dimensionId).unitShort
										}
									</Typography>
								</Grid>
								<Grid item xs="auto">
									<Typography variant="overline" fontWeight={600}>
										&bull;
									</Typography>
								</Grid>
								<Grid item xs="auto">
									<Typography variant="overline" fontWeight={600}>
										{
											Moment(props.allChallenges.find((ch) => ch.id === challenge).sd).format("MMM D, YYYY")
											+ " - " +
											Moment(props.allChallenges.find((ch) => ch.id === challenge).ed).format("MMM D, YYYY")
										}
									</Typography>
								</Grid>
							</Grid>
							<Typography variant="caption" display="block">
								{challengeDetails.description}
							</Typography>
						</CardContent>
					</Card>
				</CardContent>
				<Paper sx={{ width: "100%", overflow: "hidden" }}>
					<TableContainer>
						<Table stickyHeader aria-label="sticky table" size="small">
							<TableHead>
								<TableRow>
									{columns.map((column, i) => (
										<TableCell
											key={column.id}
											align={column.align}
											sx={i === 1 ? { p: "6px" } : {}}
										>
											{loaded && renderColumnName(column.id, column.label)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{loaded &&
									listItems
										.slice((page - 1) * 10, page * 10)
										.map((row, index) => (
											<TableRow key={index}>
												<TableCell
													align="left"
													sx={{
														borderLeft: 4,
														borderLeftColor:
															row.v >= challengeDetails.goal
																? "success.main"
																: "transparent",
													}}
												>
													{row.r}
												</TableCell>
												<TableCell
													align="left"
													sx={{
														whiteSpace: "nowrap",
														maxWidth: 190,
														p: "6px",
													}}
												>
													<span
														style={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
														}}
													>
														<Avatar
															alt={row.n}
															src={row.a}
															sx={{ mr: 1, width: 24, height: 24 }}
														/>
														{challenge > -1 &&
															!props.allChallenges.find(
																(ch) => ch.id === challenge
															).te ? (
															<Link
																sx={{
																	cursor: "pointer",
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																}}
																onClick={() => props.displayCard(row.i)}
															>
																{row.n}
															</Link>
														) : (
															<span
																style={{
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																}}
															>
																{row.n}
															</span>
														)}
													</span>
												</TableCell>
												<TableCell
													align="right"
													sx={{ whiteSpace: "nowrap" }}
												>
													{formatAmount(
														row.v,
														props.dimensions.find(
															(dim) => dim.id === challengeDetails.dimensionId
														).name
													)}
												</TableCell>
											</TableRow>
										))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={totalParticipants}
						rowsPerPage={10}
						rowsPerPageOptions={[10]}
						page={loaded ? page - 1 : 0}
						onPageChange={handleChangePage}
					/>
				</Paper>
			</>
		) : (
			<CardContent>
				<Skeleton variant="rounded" width="100%" height={36} sx={{ mb: 2 }} />
				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					spacing={1}
				>
					<Grid item>
						<Skeleton variant="rounded" width="100%" height={16} />
					</Grid>
					<Grid item>
						<Skeleton variant="rounded" width="33%" height={16} />
					</Grid>
				</Grid>
			</CardContent>
		)
	);
}
